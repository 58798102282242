import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, IconButton, Rating, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red } from '@mui/material/colors';
import classNames from 'classnames';
import { spacing } from '@mui/system';
import styles from './review-card.module.css';

export function ReviewCard({ title, company, avatar }) {

    return (
        <Box sx={{ px: .5, py: .5 }} className={styles.wrapper}>
            <Card className={classNames(styles.review_card)}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500], width: avatar.width, height: avatar.height }} aria-label="review" className={classNames(styles.header__avatar)}>
                            R
                        </Avatar>
                    }

                    title={title}
                    subheader={company}
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    className={classNames('review_card__header')}
                />

                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        This impressive paella is a perfect party dish and a fun meal to cook
                        together with your guests. Add 1 cup of frozen peas along with the mussels,
                        if you like.
                    </Typography>
                    <Box sx={{ marginTop: '15px' }} className="">
                        <Rating
                            name="simple-controlled"
                            value={4}
                            readOnly
                        />
                    </Box>

                </CardContent>

            </Card>
        </Box>
    )
}