class Api {
  #baseUrl;
  #headers;

  constructor({ baseUrl, headers }) {
    this.#baseUrl = baseUrl;
    this.#headers = headers;
  }

  #onResponse(res) {
    return res.ok ? res.json() : res.json().then((err) => Promise.reject(err));
  }

  #getApiUrl(path) {
    return `${this.#baseUrl}${path}`;
  }

  getPostsList() {
    return fetch(this.#getApiUrl('/wp-json/wp/v2/posts'), {
      headers: this.#headers,
    }).then(this.#onResponse);
  }
}

const api = new Api({
  baseUrl: 'https://api-sb.bs-net.ru',
  headers: {
    'content-type': 'application/json',
    authorization:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjIsIm5hbWUiOiJCcmFpblN0b3JtIiwiaWF0IjoxNjc5OTk4MzYzLCJleHAiOjE4Mzc2NzgzNjN9.XK9j9Luw6YI-OdOZfxNJaA3v0eTEII0xNXg-ocS90kU',
  },
});

export default api;
