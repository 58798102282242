import { Header } from '../header';
import { Footer } from '../footer';
import styles from "./app.module.css";
import classNames from 'classnames';
import { Navbar } from '../navbar';
import { PageHome } from '../../pages/home';

export function App() {
  return (
    <>
      <Header>
        <Navbar/>
      </Header>
      
      <PageHome/>

      <Footer/>
    </>
  );
}