import { Avatar, CircularProgress, Container, Divider, Grid, LinearProgress, Link, Typography } from '@mui/material';


import classNames from 'classnames';
import styles from './home.module.css';
import { ReviewCard } from '../../components/review-card';
import { B8Carousel } from '../../components/carousel';
import { Box } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTelegram, faVk, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import api from '../../utils.js/api';

export function PageHome() {

  let reviewsSlides = [
    {
      title: '555',
      company: '444',
    },
    {
      title: '666',
      company: '777',
    }
  ];

  api.getPostsList()
  .then((productsData) => {
    console.log(productsData);
  })
  .catch(err => console.log(err));
 

  return (
    <>
      <main className={classNames(styles.container)}>
        <Container maxWidth="lg">
          <Grid container columnSpacing={{ xs: 3 }}>
            <Grid item xs={6} md={3}>
              <Box className={classNames(styles.header)}>
                <Avatar
                  alt="Remy Sharp"
                  src="https://brain8torm.ru/images/bs-newlogo-3-4-100-100.png"
                  sx={{ width: 100, height: 100 }}
                  className={classNames(styles.header_avatar)}
                />
                <Box className={classNames(styles.header_name)}>BrainStorm</Box>
                <Box className={classNames(styles.header_text)}>Веб-разработчик</Box>
              </Box>

              <Box className={classNames(styles.table)}>
                <ul>
                  <li><span>Город:</span><span>Вышний Волочек</span></li>
                  <li><span>Часовой пояс:</span><span>UTC+3 MSK</span></li>
                  <li><span>Возраст:</span><span>42 года</span></li>
                </ul>
              </Box>
              <Divider />
              <Box className={classNames(styles.lang_skills)}>
                <div className={classNames(styles.lang_skills__item)}>
                  <CircularProgress variant="determinate" value={100} className={classNames(styles.lang_skills__item_progress)} />
                  <div className={classNames(styles.lang_skills__item_text)}>Русский</div>
                </div>
                <div className={classNames(styles.lang_skills__item)}>
                  <CircularProgress variant="determinate" value={60} className={classNames(styles.lang_skills__item_progress)} />
                  <div className={classNames(styles.lang_skills__item_text)}>Немецкий</div>
                </div>
                <div className={classNames(styles.lang_skills__item)}>
                  <CircularProgress variant="determinate" value={60} className={classNames(styles.lang_skills__item_progress)} />
                  <div className={classNames(styles.lang_skills__item_text)}>Английский</div>
                </div>
              </Box>
              <Divider />
              <Box className={classNames(styles.hard_skills)}>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>html</div>
                    <div className={styles.hard_skills__item_head_value}>90 %</div>
                  </div>
                  <LinearProgress variant="determinate" value={90} />
                </div>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>css</div>
                    <div className={styles.hard_skills__item_head_value}>85 %</div>
                  </div>
                  <LinearProgress variant="determinate" value={85} />
                </div>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>js</div>
                    <div className={styles.hard_skills__item_head_value}>65 %</div>
                  </div>
                  <LinearProgress variant="determinate" value={65} />
                </div>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>php</div>
                    <div className={styles.hard_skills__item_head_value}>50 %</div>
                  </div>
                  <LinearProgress variant="determinate" value={50} />
                </div>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>wordpress</div>
                    <div className={styles.hard_skills__item_head_value}>80 %</div>
                  </div>
                  <LinearProgress variant='determinate' value={80} />
                </div>
                <div className={classNames(styles.hard_skills__item)}>
                  <div className={styles.hard_skills__item_head}>
                    <div className={styles.hard_skills__item_head_title}>React</div>
                    <div className={styles.hard_skills__item_head_value}>10 %</div>
                  </div>
                  <LinearProgress variant='determinate' value={10} />
                </div>
              </Box>
              <Box className={classNames(styles.social_links)}>
                <Link href='#' underline='none' target='_blank' rel='noopener' className={classNames(styles.social_links__link)}>
                  <FontAwesomeIcon icon={faVk} size={'2x'} />
                </Link>
                <Link href='#' underline='none' target='_blank' rel='noopener' className={classNames(styles.social_links__link)}>
                  <FontAwesomeIcon icon={faWhatsapp} size={'2x'} />
                </Link>
                <Link href='#' underline='none' target='_blank' rel='noopener' className={classNames(styles.social_links__link)}>
                  <FontAwesomeIcon icon={faTelegram} size={'2x'} />
                </Link>
                <Link href='#' underline='none' target='_blank' rel='noopener' className={classNames(styles.social_links__link)}>
                  <FontAwesomeIcon icon={faGithub} size={'2x'} />
                </Link>
              </Box>
            </Grid>


            <Grid item xs={6} md={9}>
              <div className={classNames(styles.content)}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={4}>
                    111
                  </Grid>
                  <Grid item xs={4}>
                    222
                  </Grid>
                  <Grid item xs={4}>
                    3333
                  </Grid>
                </Grid>

                <Typography variant="h3" gutterBottom mt={2}>
                  Отзывы
                </Typography>

                <B8Carousel />

              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  );
}