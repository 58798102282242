import { Box, IconButton } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import { ReviewCard } from '../review-card';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import styles from './carousel.module.css';

export function B8Carousel(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: (
      <IconButton aria-label='next slide' size='small'>
        <ChevronRightIcon color='action' />
      </IconButton>
    ),
      prevArrow: (
        <IconButton aria-label='prev slide' size='small'>
              <ChevronLeftIcon color='action' />
        </IconButton>
    ),
    //appendArrows: '.carousel__wrapper .carousel__arrows',
    //appendDots: '.carousel__wrapper .carousel__dots',
  };
  return (
    <Box className={classNames(styles.wrapper, 'carousel__wrapper')}>
      <Slider {...settings}>
        <ReviewCard
          title={'Paul Trueman'}
          company={555}
          avatar={{ width: 65, height: 65 }}
        />
        <ReviewCard
          title={'Paul Trueman'}
          company={555}
          avatar={{ width: 65, height: 65 }}
        />
        <ReviewCard
          title={'Paul Trueman'}
          company={555}
          avatar={{ width: 65, height: 65 }}
        />
      </Slider>
      <Box className={classNames(styles.navigation)}>
        <Box className={classNames(styles.dots, 'carousel__dots')}></Box>
        <Box className={classNames(styles.arrows, 'carousel__arrows')}></Box>
      </Box>
    </Box>
  );
}
